import React, { useState } from "react";
import "./EntryPage.css";

function EntryPage({ onStart, onFaultEntry }) {
  const [latLng, setLatLng] = useState("");
  const [error, setError] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);
  const [faultDate, setFaultDate] = useState("");
  const [isMapDragOver, setIsMapDragOver] = useState(false);
  const [isFaultDragOver, setIsFaultDragOver] = useState(false);

  const [loadedData, setLoadedData] = useState(null);

  const handleLatLngChange = (e) => {
    setLatLng(e.target.value);
    setError("");
  };

  const handleFaultDateChange = (e) => {
    setFaultDate(e.target.value);
    setError("");
  };

  const handleCreateNewMap = () => {
    const [lat, lng] = latLng.split(",").map((val) => parseFloat(val.trim()));
    if (!isNaN(lat) && !isNaN(lng) && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
      onStart({ lat, lng });
    } else {
      setError("Invalid latitude and longitude. Use format: lat, lng");
    }
  };

  const handleMapDragOver = (e) => {
    e.preventDefault();
    setIsMapDragOver(true);
  };

  const handleMapDragLeave = () => {
    setIsMapDragOver(false);
  };

  const handleFaultDragOver = (e) => {
    e.preventDefault();
    setIsFaultDragOver(true);
  };

  const handleFaultDragLeave = () => {
    setIsFaultDragOver(false);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];

    if (
      file &&
      (file.type === "application/json" ||
        file.name.endsWith(".json") ||
        file.name.endsWith(".geojson"))
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const geoJson = JSON.parse(e.target.result);
          if (
            geoJson.type === "FeatureCollection" &&
            Array.isArray(geoJson.features) &&
            geoJson.features.length > 0 &&
            geoJson.features[0].geometry &&
            geoJson.features[0].geometry.coordinates
          ) {
            const firstCoordinates = geoJson.features[0].geometry.coordinates[0][0];
            if (Array.isArray(firstCoordinates) && firstCoordinates.length === 2) {
              const [lng, lat] = firstCoordinates;
              if (!isNaN(lat) && !isNaN(lng)) {
                onStart({ geoJson, lat, lng });
                setError(""); // Clear errors
                alert("GeoJSON file loaded successfully!");
              } else {
                setError("Invalid coordinates in the first feature.");
              }
            } else {
              setError("Invalid coordinate structure in the GeoJSON file.");
            }
          } else {
            setError("Invalid GeoJSON structure. Expected a FeatureCollection with valid geometry.");
          }
        } catch (error) {
          setError("Error parsing the GeoJSON file. Ensure it is valid JSON.");
        }
      };
      reader.readAsText(file);
    } else {
      setError("Please upload a valid .json or .geojson file with a GeoJSON structure.");
    }
  };

  const handleFaultFileDrop = (e) => {
    e.preventDefault();
    setIsFaultDragOver(false);
    const file = e.dataTransfer.files[0];

    if (file && (file.type === "application/json" || file.name.endsWith(".json"))) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const parsedData = JSON.parse(e.target.result);

          if (parsedData.date && Array.isArray(parsedData.faults)) {
            alert("Work-in-progress JSON uploaded successfully!");
            setLoadedData(parsedData.faults);
            setFaultDate(parsedData.date); 

            onFaultEntry(parsedData.date, parsedData.faults);
          } else {
            setError("Invalid JSON structure. Ensure it has a 'date' field and a 'faults' array.");
          }
        } catch (error) {
          setError("Error parsing the JSON file. Ensure it is valid JSON.");
        }
      };
      reader.readAsText(file);
    } else {
      setError("Please upload a valid Fault JSON file with a .json extension.");
    }
  };



  return (
    <div className="entry-page">
      <h1>Horizon View's Map Generator</h1>

      <div className="entry-container">

        {/*Left Square */}
        <div className="entry-box">
          <h2>Map Drawing</h2>
          <p>Enter a starting location or load an existing map.</p>
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter Latitude, Longitude"
              value={latLng}
              onChange={handleLatLngChange}
            />
            <button className="entry-button" onClick={handleCreateNewMap}>Create New Map</button>
          </div>
          <div>
            <p>Or</p>
          </div>
          <div
            className={`drag-drop-area ${isDragOver ? "drag-over" : ""}`}
            onDragOver={handleMapDragOver}
            onDragLeave={handleMapDragLeave}
            onDrop={handleFileDrop}
          >
            Drag and drop a valid map file here
          </div>
        </div>

        {/*right square*/}
        <div className="entry-box">
          <h2>Fault Entry</h2>
          <p>Enter analysis date for fault entry</p>
          <div className="input-container">
            <input
              type="text"
              placeholder="Enter date (DD/MM/YYYY)"
              value={faultDate}
              onChange={handleFaultDateChange}
            />
            <button
              className="entry-button"
              onClick={() => {
                if (/^\d{8}$/.test(faultDate)) {
                  onFaultEntry(faultDate);
                } else {
                  setError("Please enter a valid date in DDMMYYYY format.");
                }
              }}
            >
              Start Fault Entry
            </button>
          </div>
          <div>
            <p>Or</p>
          </div>
          <div
            className={`drag-drop-area ${isFaultDragOver ? "drag-over" : ""}`}
            onDragOver={handleFaultDragOver}
            onDragLeave={handleFaultDragLeave}
            onDrop={handleFaultFileDrop}
          >
            Drag and drop a Fault JSON file here
          </div>

        </div>
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default EntryPage;
