import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./MapPage.css";

mapboxgl.accessToken = "pk.eyJ1IjoiamlhY2hlbnpoYW8iLCJhIjoiY200dzJiazU5MGE5YzJqcGt0bmRiM3N2NiJ9.rq1oxS59AJUgdATp8ZP-yw";

function FaultEntry({ geoJson: initialGeoJson, faultDate, faultData }) {
    const mapContainer = useRef(null);
    const map = useRef(null);

    const [geoJson, setGeoJson] = useState(initialGeoJson || { type: "FeatureCollection", features: [] });
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [isGeoJsonLoaded, setIsGeoJsonLoaded] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [isPromptOpen, setIsPromptOpen] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [faultDetails, setFaultDetails] = useState({ category: "", level: "", notes: "" });
    const [isFaultLogged, setIsFaultLogged] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [faultCounter, setFaultCounter] = useState(1);

    const [imageOverlay, setImageOverlay] = useState(null);
    const [faults, setFaults] = useState(faultData || []);

    useEffect(() => {
        if (map.current) {
            map.current.on("click", "geojson-layer", handlePolygonClick);
        }

        return () => {
            if (map.current) {
                map.current.off("click", "geojson-layer", handlePolygonClick);
            }
        };
    }, [selectedLocations, geoJson]);

    useEffect(() => {
        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/satellite-streets-v11",
                center: [-110.828147, 44.460453],
                zoom: 15,
            });

            map.current.on("load", () => {
                if (geoJson && geoJson.features && geoJson.features.length > 0) {
                    loadGeoJsonToMap();
                }
            });
        };
    }, []);

    useEffect(() => {
        if (map.current && isGeoJsonLoaded) {
            loadGeoJsonToMap();
        }
    }, [selectedLocations]);

    useEffect(() => {
        if (faultData) {
            setFaults(faultData);
        }
    }, [faultData]);

    useEffect(() => {
        if (map.current && geoJson.features && geoJson.features.length > 0) {
            if (map.current.getSource("geojson")) {
                map.current.getSource("geojson").setData(geoJson);
            } else {
                loadGeoJsonToMap();
            }
        }
    }, [geoJson]);

    useEffect(() => {
        if (isFaultLogged) {
            loadGeoJsonToMap();
            setIsFaultLogged(false);
        }
    }, [isFaultLogged]);


    const loadGeoJsonToMap = () => {
        if (!geoJson || !geoJson.features || geoJson.features.length === 0) {
            console.error("GeoJSON is empty or invalid.");
            return;
        }

        const faultDataMap = faults.reduce((acc, fault) => {
            acc[fault.location] = fault.level;
            return acc;
        }, {});

        const colorExpression = [
            "case",
            ["==", ["get", "location"], null], "#010101",
        ];

        const opacityExpression = [
            "case",
            ["==", ["get", "location"], null], 0.95,
        ];

        Object.entries(faultDataMap).forEach(([location, level]) => {
            const color =
                level === "Level 1" ? "#E00000" :
                    level === "Level 2" ? "#E05600" :
                        level === "Level 3" ? "#E0B000" :
                            "#010101";

            colorExpression.push(["==", ["get", "location"], location], color);
            opacityExpression.push(["==", ["get", "location"], location], 0.95);
        });

        selectedLocations.forEach((location) => {
            colorExpression.push(["==", ["get", "location"], location], "#00FF00");
            opacityExpression.push(["==", ["get", "location"], location], 1);
        });

        colorExpression.push("#010101");
        opacityExpression.push(0.2);

        if (map.current.getLayer("geojson-layer")) {
            map.current.removeLayer("geojson-layer");
        }
        if (map.current.getSource("geojson")) {
            map.current.removeSource("geojson");
        }

        map.current.addSource("geojson", {
            type: "geojson",
            data: geoJson,
        });
        map.current.addLayer(
            {
                id: "geojson-layer",
                type: "fill",
                source: "geojson",
                paint: {
                    "fill-color": colorExpression,
                    "fill-opacity": opacityExpression,
                    "fill-outline-color": "#010101",
                },
            },
        );

        setIsGeoJsonLoaded(true);
    };

    const handlePolygonClick = (e) => {
        if (e.features && e.features.length > 0) {
            const location = e.features[0].properties.location;
            setSelectedLocations((prev) => {
                if (prev.includes(location)) {
                    // Remove the location if it's already selected
                    return prev.filter((loc) => loc !== location);
                } else {
                    // Add the location if it's not already selected
                    return [...prev, location];
                }
            });
        }
    };

    const handleAddToFault = () => {
        setIsModalOpen(true);
    }

    const handleCancelSelection = () => {
        setSelectedLocations([]);
    };

    const calculateCentroid = (coordinates) => {
        if (!coordinates || coordinates.length === 0) return { lat: 0, lng: 0 };

        let totalLat = 0, totalLng = 0, totalPoints = 0;

        coordinates[0].forEach(([lng, lat]) => {
            totalLat += lat;
            totalLng += lng;
            totalPoints++;
        });

        return {
            lat: totalLat / totalPoints,
            lng: totalLng / totalPoints,
        };
    };

    const handleSaveFault = () => {
        const { category, level, notes } = faultDetails;

        if (!faultDate) {
            alert("Fault date is required for serialization!");
            return;
        }
        const faultID = `${faultDate}-${faultCounter}`;

        handleUpdateFault("level", level);
        handleUpdateFault("category", category);
        handleUpdateFault("notes", notes);

        const newFaultEntries = selectedLocations.map((location) => {
            const feature = geoJson.features.find(
                (feature) => feature.properties.location === location
            );

            const centroid = calculateCentroid(feature?.geometry?.coordinates);

            feature.properties.level = level;
            feature.properties.category = category;
            feature.properties.notes = notes;

            return {
                ID: faultID,
                location,
                category,
                level,
                notes,
                latitude: centroid.lat,
                longitude: centroid.lng,
            };
        });

        setFaults((prev) => [...prev, ...newFaultEntries]);
        setGeoJson({ ...geoJson, features: [...geoJson.features] });

        setFaultCounter((prev) => prev + 1);
        setSelectedLocations([]);
        setIsModalOpen(false);
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file && (file.type === "application/json" || file.name.endsWith(".json") || file.name.endsWith(".geojson"))) {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const parsedGeoJson = JSON.parse(event.target.result);
                    if (parsedGeoJson.type === "FeatureCollection" && Array.isArray(parsedGeoJson.features)) {
                        setGeoJson(parsedGeoJson);
                        setSelectedFeature(null);
                        const firstFeature = parsedGeoJson.features[0];
                        if (firstFeature && firstFeature.geometry && firstFeature.geometry.coordinates) {
                            const [lng, lat] = firstFeature.geometry.coordinates[0][0];
                            map.current.flyTo({ center: [lng, lat], zoom: 18 });
                        }

                        setIsPromptOpen(false);
                    } else {
                        alert("Invalid GeoJSON format. Please upload a valid FeatureCollection.");
                    }
                } catch (error) {
                    alert("Error parsing GeoJSON. Ensure the file is valid.");
                }
            };
            reader.readAsText(file);
        } else {
            alert("Please upload a valid .json or .geojson file.");
        }
    };

    const handleUpdateFault = (key, value) => {
        if (selectedFeature) {
            const updatedFeatures = geoJson.features.map((feature) => {
                if (feature.id === selectedFeature.id) {
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            [key]: value,
                        },
                    };
                }
                return feature;
            });

            const updatedGeoJson = { ...geoJson, features: updatedFeatures };
            setGeoJson(updatedGeoJson);

            if (map.current.getSource("geojson")) {
                map.current.getSource("geojson").setData(updatedGeoJson);
            }

        }
    };

    const handleExportFaultData = () => {
        const exportData = {
            date: faultDate,
            faults: faults,
        };

        const blob = new Blob([JSON.stringify(exportData, null, 2)], {
            type: "application/json",
        });

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${faultDate || "fault_data"}.json`;
        link.click();
    };

    const handleAdditionalFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file && (file.type === "application/json" || file.name.endsWith(".json") || file.name.endsWith(".geojson"))) {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const parsedGeoJson = JSON.parse(event.target.result);
                    if (parsedGeoJson.type === "FeatureCollection" && Array.isArray(parsedGeoJson.features)) {
                        let combinedFeatures = [...geoJson.features, ...parsedGeoJson.features];
                        if (combinedFeatures.length > 10000) {
                            const excess = combinedFeatures.length - 10000;
                            combinedFeatures = combinedFeatures.slice(excess);
                        }

                        setGeoJson({
                            type: "FeatureCollection",
                            features: combinedFeatures,
                        });
                    } else {
                        alert("Invalid GeoJSON format. Please upload a valid FeatureCollection.");
                    }
                } catch (error) {
                    alert("Error parsing GeoJSON. Ensure the file is valid.");
                }
            };
            reader.readAsText(file);
        } else {
            alert("Please upload a valid .json or .geojson file.");
        }
    };

    useEffect(() => {
        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/satellite-streets-v11",
                center: [-110.828147, 44.460453],
                zoom: 15,
            });

            map.current.on("load", () => {
                addImageOverlay();
                if (geoJson && geoJson.features && geoJson.features.length > 0) {
                    loadGeoJsonToMap();
                }


            });
        }
    }, []);

    const handleImageDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file && (file.type.startsWith("image/") || file.name.match(/\.(png|jpg|jpeg)$/))) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const imageURL = event.target.result;
                const center = map.current.getCenter();
                const initialBounds = [
                    [center.lng - 0.005, center.lat + 0.005],
                    [center.lng + 0.005, center.lat + 0.005],
                    [center.lng + 0.005, center.lat - 0.005],
                    [center.lng - 0.005, center.lat - 0.005],
                ];
                setImageOverlay({ url: imageURL, bounds: initialBounds });
                addImageOverlay(imageURL, initialBounds);
            };
            reader.readAsDataURL(file);
        } else {
            alert("Please upload a valid image file (PNG, JPG, JPEG).");
        }
    };


    const toggleImageOverlay = () => {
        if (!map.current.getLayer("image-overlay-layer")) return;

        const visibility = map.current.getLayoutProperty("image-overlay-layer", "visibility");
        if (visibility === "visible") {
            map.current.setLayoutProperty("image-overlay-layer", "visibility", "none");
        } else {
            map.current.setLayoutProperty("image-overlay-layer", "visibility", "visible");
        }
    };

    const addImageOverlay = (imageURL, bounds) => {
        if (!map.current) return;

        if (!map.current.getSource("image-overlay")) {
            map.current.addSource("image-overlay", {
                type: "image",
                url: imageURL,
                coordinates: bounds,
            });

            map.current.addLayer({
                id: "image-overlay-layer",
                type: "raster",
                source: "image-overlay",
                paint: {
                    "raster-opacity": 1,
                },
            });
        } else {
            map.current.getSource("image-overlay").updateImage({
                url: imageURL,
                coordinates: bounds,
            });
        }
    };

    const [isBoundsModalOpen, setIsBoundsModalOpen] = useState(false);
    const [boundsInput, setBoundsInput] = useState({ north: "", south: "", east: "", west: "" });
    const handleBoundsSubmit = () => {
        const { north, south, east, west } = boundsInput;
        if (!north || !south || !east || !west) {
            alert("Please fill in all fields with valid coordinates.");
            return;
        }

        const northVal = parseFloat(north);
        const southVal = parseFloat(south);
        const eastVal = parseFloat(east);
        const westVal = parseFloat(west);

        if (isNaN(northVal) || isNaN(southVal) || isNaN(eastVal) || isNaN(westVal)) {
            alert("Invalid coordinates. Please enter numeric values.");
            return;
        }

        const newBounds = [
            [westVal, northVal],
            [eastVal, northVal],
            [eastVal, southVal],
            [westVal, southVal],
        ];


        if (imageOverlay) {
            map.current.getSource("image-overlay").updateImage({
                url: imageOverlay.url,
                coordinates: newBounds,
            });
            setImageOverlay({ ...imageOverlay, bounds: newBounds });
        }

        setIsBoundsModalOpen(false);
    };

    const handleBoundsInputChange = (field, value) => {
        setBoundsInput((prev) => ({ ...prev, [field]: value }));
    };

    const levelToCategoryMap = {
        "Level 1": ["String", "Combiner", "Inverter", "Shading"],
        "Level 2": ["Tracker", "Inverter Thermal Issue", "Optimizer MPPT", "Soiling", "Broken Module"],
        "Level 3": [
            "Module Hotspot",
            "Module Mult. Hotspot",
            "Module Diode Issue",
            "Module Missing",
            "Mod. Moister Ingress",
        ],
    };

    return (
        <div className="page-wrapper">

            {isPromptOpen && (
                <div className="prompt-overlay">
                    <div className="prompt-box">
                        <h3>Start Fault Entry</h3>
                        <p>Drag and drop the GeoJSON file for the first inverter block to start fault entry.</p>
                        <div
                            className="drag-drop-area"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleFileDrop}
                        >
                            Drag and drop a GeoJSON file here
                        </div>
                    </div>
                </div>
            )}

            {isBoundsModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-box">
                        <h3>Set Overlay Bounds</h3>
                        <p>Enter coorisponding lat/lng to set orthomosaic boundaries:</p>
                        <div>
                            <label>North: </label>
                            <input
                                type="text"
                                value={boundsInput.north}
                                onChange={(e) => handleBoundsInputChange("north", e.target.value)}
                            />
                        </div>
                        <div>
                            <label>South: </label>
                            <input
                                type="text"
                                value={boundsInput.south}
                                onChange={(e) => handleBoundsInputChange("south", e.target.value)}
                            />
                        </div>
                        <div>
                            <label>East: </label>
                            <input
                                type="text"
                                value={boundsInput.east}
                                onChange={(e) => handleBoundsInputChange("east", e.target.value)}
                            />
                        </div>
                        <div>
                            <label>West: </label>
                            <input
                                type="text"
                                value={boundsInput.west}
                                onChange={(e) => handleBoundsInputChange("west", e.target.value)}
                            />
                        </div>
                        <div className="modal-buttons">
                            <button onClick={() => setIsBoundsModalOpen(false)}>Cancel</button>
                            <button onClick={handleBoundsSubmit}>Set Bounds</button>
                        </div>
                    </div>
                </div>
            )}

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-box">
                        <h3>Enter Fault Data</h3>
                        <p>
                            <strong>Fault Level: </strong>
                            <select
                                value={faultDetails.level}
                                onChange={(e) => setFaultDetails({ ...faultDetails, level: e.target.value, category: "" })}
                            >
                                <option value="">  </option>
                                <option value="Level 1">Level 1</option>
                                <option value="Level 2">Level 2</option>
                                <option value="Level 3">Level 3</option>
                            </select>
                        </p>
                        <p>
                            <strong>Fault Category: </strong>
                            <select
                                value={faultDetails.category}
                                onChange={(e) => setFaultDetails({ ...faultDetails, category: e.target.value })}
                                disabled={!faultDetails.level}
                            >
                                <option value=""></option>
                                {faultDetails.level &&
                                    levelToCategoryMap[faultDetails.level].map((category, index) => (
                                        <option key={index} value={category}>
                                            {category}
                                        </option>
                                    ))}
                            </select>
                        </p>
                        <p>
                            <strong>Notes: </strong>

                        </p>
                        <textarea value={faultDetails.notes} onChange={(e) => setFaultDetails({ ...faultDetails, notes: e.target.value })} ></textarea>

                        <div className="modal-buttons">
                            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                            <button onClick={handleSaveFault}>Save</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="map-page">

                <div ref={mapContainer} className="map-container">
                    <div classname="filler-class">
                        <div
                            className="additional-drag-drop"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleAdditionalFileDrop}
                        >
                            <p>Drag and Drop Additional GeoJSON</p>
                        </div>

                        <div
                            className="drag-drop-overlay"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleImageDrop}
                        >
                            Drag and drop image overlay
                        </div>

                        <div className="overlay-toggle">
                            <button onClick={toggleImageOverlay}>Toggle Image Overlay</button>
                        </div>

                        <div className="set-bounds">
                            <button onClick={() => setIsBoundsModalOpen(true)}>Set Overlay Bounds</button>
                        </div>

                    </div>
                </div>

                <div
                    className={`hamburger-icon ${isSidePanelOpen ? "shifted" : ""}`}
                    onClick={() => setIsSidePanelOpen(!isSidePanelOpen)}
                    title="View Fault Data"
                >
                    &#9776;
                </div>

                {selectedLocations.length > 0 && (
                    <div className="location-list">
                        <h3>Panels Selected</h3>
                        <ul>
                            {selectedLocations.map((location, index) => (
                                <li key={index}>{location}</li>
                            ))}
                        </ul>
                        <div className="button-group">
                            <button onClick={handleAddToFault}> + </button>
                            <button onClick={handleCancelSelection}>Cancel</button>
                        </div>
                    </div>
                )}

                <div className={`side-panel ${isSidePanelOpen ? "open" : ""}`}>
                    <h3>Fault Data Viewer</h3>
                    <button onClick={handleExportFaultData}>Export Fault Data</button>
                    <pre>{JSON.stringify(faults, null, 2)}</pre>
                </div>

            </div>
        </div>
    );
}

export default FaultEntry;
