import React, { useState } from "react";
import EntryPage from "./components/EntryPage";
import MapPage from "./components/MapPage";
import FaultEntry from "./components/FaultEntry";

function App() {
  const [appState, setAppState] = useState(null); 
  const [faultData, setFaultData] = useState(null); 
  const [faultDate, setFaultDate] = useState(null);

  const handleStart = (data) => {
    setAppState({ page: "map", ...data }); 
  };

  const handleFaultEntry = (date, faults = []) => {
    setAppState({ page: "fault", faultDate: date }); 
    setFaultDate(date);
    setFaultData(faults);
  };

  if (!appState) {
    return <EntryPage onStart={handleStart} onFaultEntry={handleFaultEntry} />;
  }

  return (
    <div className="app-container">
      {appState.page === "map" && (
        <MapPage lat={appState.lat} lng={appState.lng} geoJson={appState.geoJson} />
      )}
     {appState.page === "fault" && (
        <FaultEntry
          geoJson={{}}
          faultDate={appState.faultDate}
          faultData = {faultData}
        />
      )}
    </div>
  );
}

export default App;
