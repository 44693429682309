import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import DeleteIcon from '@mui/icons-material/Delete';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import CropSquareIcon from '@mui/icons-material/CropSquare'; 
import LabelIcon from '@mui/icons-material/Label';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PanToolIcon from '@mui/icons-material/PanTool';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';


import * as turf from '@turf/turf';
import "./MapPage.css";

mapboxgl.accessToken = "pk.eyJ1IjoiamlhY2hlbnpoYW8iLCJhIjoiY200dzJiazU5MGE5YzJqcGt0bmRiM3N2NiJ9.rq1oxS59AJUgdATp8ZP-yw"; 

function MapPage({ lat, lng, geoJson: initialGeoJson }) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);

    const [geoJson, setGeoJson] = useState(initialGeoJson || { type: "FeatureCollection", features: [] });
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDrawingRectangle, setIsDrawingRectangle] = useState(false);

    const [isNeutralCursor, setIsNeutralCursor] = useState(true); 
    const [copiedPolygons, setCopiedPolygons] = useState([]);
    const [isPasting, setIsPasting] = useState(false); 

    useEffect(() => {
        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/satellite-streets-v11",
                center: [lng, lat],
                zoom: 15,
            });

            draw.current = new MapboxDraw({
                displayControlsDefault: false,
                controls: { trash: false },
            });

            map.current.addControl(draw.current);

            map.current.on("load", () => {
                map.current.addSource("geojson", {
                    type: "geojson",
                    data: geoJson,
                });

                map.current.addLayer({
                    id: "geojson-layer",
                    type: "fill",
                    source: "geojson",
                    paint: {
                        "fill-color": "#0080ff",
                        "fill-opacity": 0.5,
                        "fill-outline-color": "#003366",
                    },
                });
            });
        }

        const handleDrawDelete = (e) => {
            const remainingFeatures = geoJson.features.filter(
                (feature) => !e.features.some((deleted) => deleted.id === feature.id)
            );
            setGeoJson({ ...geoJson, features: remainingFeatures });
            map.current.getSource("geojson").setData({ ...geoJson, features: remainingFeatures });
        };

        map.current?.on("draw.delete", handleDrawDelete);

        return () => {
            map.current?.off("draw.delete", handleDrawDelete);
        };
    }, [geoJson, lat, lng]);

    useEffect(() => {
        if (!map.current) return;
    
        const handleRightClick = (e) => {
            const features = map.current.queryRenderedFeatures(e.point, {
                layers: ['geojson-layer'], 
            });
    
            if (features.length > 0) {
                const feature = features[0];
                const properties = feature.properties;
                new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(`
                        <div>
                            <h4>Panel Location</h4>
                            <pre>${JSON.stringify(properties, null, 2)}</pre>
                        </div>
                    `)
                    .addTo(map.current);
            }
        };
    
        map.current.on('contextmenu', handleRightClick);
    
        return () => {
            map.current.off('contextmenu', handleRightClick);
        };
    }, []);
    

    
    const handleToggleSidePanel = () => {
        setIsSidePanelOpen(!isSidePanelOpen);
    };

    const handleDeleteArea = () => {
        let rectangleStart = null;
        let isDrawingRectangle = true;
    
        map.current.getCanvas().style.cursor = 'crosshair';
        const handleFirstClick = (e) => {
            rectangleStart = [e.lngLat.lng, e.lngLat.lat];
            map.current.on('mousemove', handleMouseMove);
            map.current.once('click', handleSecondClick);
        };
        const handleMouseMove = (e) => {
            if (!rectangleStart) return;
            drawRectanglePreview(rectangleStart, [e.lngLat.lng, e.lngLat.lat]);
        };
        const handleSecondClick = (e) => {
            if (!rectangleStart) return;
    
            const rectangleEnd = [e.lngLat.lng, e.lngLat.lat];
            const bounds = {
                west: Math.min(rectangleStart[0], rectangleEnd[0]),
                east: Math.max(rectangleStart[0], rectangleEnd[0]),
                south: Math.min(rectangleStart[1], rectangleEnd[1]),
                north: Math.max(rectangleStart[1], rectangleEnd[1]),
            };
            const confirmDelete = window.confirm(
                'Are you sure you want to delete all polygons in this area?'
            );
    
            if (confirmDelete) {
                deletePolygonsInArea(bounds);
            }
            isDrawingRectangle = false;
            rectangleStart = null;
            map.current.off('mousemove', handleMouseMove);
            map.current.getCanvas().style.cursor = '';
            if (map.current.getSource('preview')) {
                map.current.removeLayer('preview-layer');
                map.current.removeSource('preview');
            }
        };
    
        map.current.once('click', handleFirstClick);
    };
    const deletePolygonsInArea = (bounds) => {
        const filteredFeatures = geoJson.features.filter((feature) => {
            if (feature.geometry.type !== 'Polygon') return true;
            return feature.geometry.coordinates[0].every(([lng, lat]) => {
                return (
                    lng < bounds.west || 
                    lng > bounds.east || 
                    lat < bounds.south || 
                    lat > bounds.north
                );
            });
        });
    
        setGeoJson({ ...geoJson, features: filteredFeatures });
        map.current.getSource('geojson').setData({
            ...geoJson,
            features: filteredFeatures,
        });
    };

    const handleSaveEditedGeoJson = () => {
        try {
            const parsedGeoJson = JSON.parse(document.getElementById("geojson-textarea").value);
            if (parsedGeoJson.type === "FeatureCollection") {
                setGeoJson(parsedGeoJson);
                map.current.getSource("geojson").setData(parsedGeoJson);
                setIsEditing(false);
            } else {
                alert("Invalid GeoJSON format. Please ensure it's a FeatureCollection.");
            }
        } catch (error) {
            alert("Error parsing GeoJSON. Please ensure the content is valid JSON.");
        }
    };

    const handleExportGeoJson = () => {
        const blob = new Blob([JSON.stringify(geoJson, null, 2)], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "geojson_data.geojson";
        link.click();
    };

    const handleExitStampMode = (e) => {
        if (e.key === 'Escape') {
            setIsPasting(false);
            setCopiedPolygons([]);
            map.current.getCanvas().style.cursor = '';
            map.current.off('mousemove', handleStampPreview);
            map.current.off('click', handleStampClick);
            window.removeEventListener('keydown', handleExitStampMode);
            if (map.current.getSource('copy-preview-source')) {
                map.current.removeLayer('copy-preview-layer');
                map.current.removeSource('copy-preview-source');
            }
    
            console.log('Stamp mode exited.');
        }
    };
    
    const handleNeutralCursor = () => {
        console.log('Neutral cursor activated. All tools reset.');
        map.current.getCanvas().style.cursor = '';
        map.current.off('mousemove');
        map.current.off('click');
        window.removeEventListener('keydown', handleExitStampMode);
        setIsDrawingRectangle(false);
        setIsPasting(false);
        setCopiedPolygons([]);
        setIsNeutralCursor(true);
        ['preview', 'copy-preview-source', 'solar-grid'].forEach((sourceId) => {
            if (map.current.getSource(sourceId)) {
                map.current.removeLayer(`${sourceId}-layer`);
                map.current.removeSource(sourceId);
            }
        });
        if (draw.current) {
            draw.current.changeMode('simple_select');
        }
    };
    
    

    const handleZoomIn = () => {
        map.current.zoomIn();
    };

    const handleZoomOut = () => {
        map.current.zoomOut();
    };

    const handleResetMap = () => {
        map.current.flyTo({ center: [lng, lat], zoom: 15 });
    };

    const handleDrawSolarGrid = () => {
        setIsDrawingRectangle(true);
        map.current.getCanvas().style.cursor = 'crosshair';
    
        let rectangleStart = null;
    
        const handleFirstClick = (e) => {
            rectangleStart = [e.lngLat.lng, e.lngLat.lat];
            map.current.on('mousemove', handleMouseMove);
            map.current.once('click', handleSecondClick);
        };
    
        const handleMouseMove = (e) => {
            if (!rectangleStart) return;
            const rectangleEnd = [e.lngLat.lng, e.lngLat.lat];
            drawRectanglePreview(rectangleStart, rectangleEnd);
        };
    
        const handleSecondClick = (e) => {
            if (!rectangleStart) return;
            const rectangleEnd = [e.lngLat.lng, e.lngLat.lat];
    
            finalizeRectangle(rectangleStart, rectangleEnd);
    
            const rows = prompt('Enter number of rows:');
            const columns = prompt('Enter number of columns:');
    
            if (rows && columns) {
                generateSolarPanelGrid(rectangleStart, rectangleEnd, parseInt(rows), parseInt(columns));
            }
            setIsDrawingRectangle(false);
            rectangleStart = null;
            map.current.off('mousemove', handleMouseMove);
            map.current.getCanvas().style.cursor = '';
        };
    
        map.current.once('click', handleFirstClick);
    };
    
    const finalizeRectangle = (start, end) => {
        const rectangle = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [[
                    [start[0], start[1]],
                    [end[0], start[1]],
                    [end[0], end[1]],
                    [start[0], end[1]],
                    [start[0], start[1]]
                ]]
            },
            properties: { type: 'solar-grid' }
        };
        if (map.current.getSource('solar-grid')) {
            map.current.getSource('solar-grid').setData(rectangle);
        } else {
            map.current.addSource('solar-grid', {
                type: 'geojson',
                data: rectangle
            });
    
            map.current.addLayer({
                id: 'solar-grid-layer',
                type: 'fill',
                source: 'solar-grid',
                paint: {
                    'fill-color': '#ff0000',
                    'fill-opacity': 0.3,
                    'fill-outline-color': '#aa0000'
                }
            });
        }
        if (map.current.getSource('solar-grid')) {
            map.current.removeLayer('solar-grid-layer');
            map.current.removeSource('solar-grid');
        }
    };
    
    const drawRectanglePreview = (start, end) => {
        const previewRectangle = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [[
                    [start[0], start[1]],
                    [end[0], start[1]],
                    [end[0], end[1]],
                    [start[0], end[1]],
                    [start[0], start[1]]
                ]]
            },
            properties: { isPreview: true }
        };
    
        if (map.current.getSource('preview')) {
            map.current.getSource('preview').setData(previewRectangle);
        } else {
            map.current.addSource('preview', {
                type: 'geojson',
                data: previewRectangle
            });
    
            map.current.addLayer({
                id: 'preview-layer',
                type: 'fill',
                source: 'preview',
                paint: {
                    'fill-color': '#00ff00',
                    'fill-opacity': 0.3,
                    'fill-outline-color': '#f0ff00'
                }
            });
        }
    };
    
    const generateSolarPanelGrid = (start, end, rows, columns) => {
        const gridFeatures = [];   
        if (rows <= 0 || columns <= 0) {
            alert('Rows and columns must be positive integers.');
            return;
        }
    
        const latStep = (end[1] - start[1]) / rows;
        const lngStep = (end[0] - start[0]) / columns;
        const precision = 10;
    
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < columns; j++) {
                const topLeft = [
                    parseFloat((start[0] + j * lngStep).toFixed(precision)),
                    parseFloat((start[1] + i * latStep).toFixed(precision))
                ];
                const topRight = [
                    parseFloat((start[0] + (j + 1) * lngStep).toFixed(precision)),
                    parseFloat((start[1] + i * latStep).toFixed(precision))
                ];
                const bottomRight = [
                    parseFloat((start[0] + (j + 1) * lngStep).toFixed(precision)),
                    parseFloat((start[1] + (i + 1) * latStep).toFixed(precision))
                ];
                const bottomLeft = [
                    parseFloat((start[0] + j * lngStep).toFixed(precision)),
                    parseFloat((start[1] + (i + 1) * latStep).toFixed(precision))
                ];
    
                gridFeatures.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [[
                            topLeft,
                            topRight,
                            bottomRight,
                            bottomLeft,
                            topLeft
                        ]]
                    },
                    properties: {
                    }
                });
            }
        }
    
        console.log(`Generated ${gridFeatures.length} grid polygons for ${rows}x${columns} grid.`);
        setGeoJson(prev => ({
            ...prev,
            features: [...prev.features, ...gridFeatures]
        }));
    
        map.current.getSource('geojson').setData({
            type: 'FeatureCollection',
            features: [...geoJson.features, ...gridFeatures]
        });
    };
    const handleStampPreview = (e) => {
        if (!isPasting || copiedPolygons.length === 0) return;
        const targetPoint = [e.lngLat.lng, e.lngLat.lat];
        const offset = calculatePolygonOffset(copiedPolygons[0], targetPoint);
        const previewPolygons = copiedPolygons.map((feature) => ({
            ...feature,
            geometry: {
                ...feature.geometry,
                coordinates: feature.geometry.coordinates.map((ring) =>
                    ring.map(([lng, lat]) => [lng + offset.lng, lat + offset.lat])
                ),
            },
        }));
    
        if (map.current.getSource('copy-preview-source')) {
            map.current.getSource('copy-preview-source').setData({
                type: 'FeatureCollection',
                features: previewPolygons,
            });
        } else {
            map.current.addSource('copy-preview-source', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: previewPolygons,
                },
            });
    
            map.current.addLayer({
                id: 'copy-preview-layer',
                type: 'fill',
                source: 'copy-preview-source',
                paint: {
                    'fill-color': '#ffcc00',
                    'fill-opacity': 0.5,
                    'fill-outline-color': '#ff9900',
                },
            });
        }
    };
    const handleStampClick = (e) => {
        if (!isPasting || copiedPolygons.length === 0) return;
    
        const targetPoint = [e.lngLat.lng, e.lngLat.lat];
        const offset = calculatePolygonOffset(copiedPolygons[0], targetPoint);
    
        const stampedPolygons = copiedPolygons.map((feature) => ({
            ...feature,
            geometry: {
                ...feature.geometry,
                coordinates: feature.geometry.coordinates.map((ring) =>
                    ring.map(([lng, lat]) => [lng + offset.lng, lat + offset.lat])
                ),
            },
        }));
    
        setGeoJson((prev) => ({
            ...prev,
            features: [...prev.features, ...stampedPolygons],
        }));
    
        map.current.getSource('geojson').setData({
            type: 'FeatureCollection',
            features: [...geoJson.features, ...stampedPolygons],
        });
    
        handleExitStampMode({ key: 'Escape' });
    };
    
    
    const handleCopyStampTool = () => {
        let rectangleStart = null;
        let copiedPolygons = [];
        let isStamping = false;
        let previewSourceId = 'copy-preview-source';
        let previewLayerId = 'copy-preview-layer';
    
        map.current.getCanvas().style.cursor = 'crosshair';
        const handleFirstClick = (e) => {
            rectangleStart = [e.lngLat.lng, e.lngLat.lat];
            map.current.on('mousemove', handleMouseMove);
            map.current.once('click', handleSecondClick);
        };
        const handleMouseMove = (e) => {
            if (!rectangleStart) return;
            const rectangleEnd = [e.lngLat.lng, e.lngLat.lat];
            drawRectanglePreview(rectangleStart, rectangleEnd);
        };
        const handleSecondClick = (e) => {
            if (!rectangleStart) return;
            const rectangleEnd = [e.lngLat.lng, e.lngLat.lat];
            const bounds = {
                west: Math.min(rectangleStart[0], rectangleEnd[0]),
                east: Math.max(rectangleStart[0], rectangleEnd[0]),
                south: Math.min(rectangleStart[1], rectangleEnd[1]),
                north: Math.max(rectangleStart[1], rectangleEnd[1]),
            };
            copiedPolygons = geoJson.features.filter((feature) => {
                if (feature.geometry.type !== 'Polygon') return false;
                return feature.geometry.coordinates[0].every(([lng, lat]) => {
                    return (
                        lng >= bounds.west && 
                        lng <= bounds.east && 
                        lat >= bounds.south && 
                        lat <= bounds.north
                    );
                });
            });
            if (copiedPolygons.length > 0) {
                const confirmCopy = window.confirm(
                    `You have selected ${copiedPolygons.length} polygons. Start stamping them?`
                );
    
                if (confirmCopy) {
                    isStamping = true;
                    map.current.getCanvas().style.cursor = 'copy';
                    map.current.on('mousemove', handleStampPreview);
                    map.current.on('click', handleStampClick);
                    window.addEventListener('keydown', handleExitStampMode);
                }
            } else {
                alert('No polygons found in the selected area.');
            }
            rectangleStart = null;
            map.current.off('mousemove', handleMouseMove);
            if (map.current.getSource('preview')) {
                map.current.removeLayer('preview-layer');
                map.current.removeSource('preview');
            }
        };
        const handleStampPreview = (e) => {
            if (!isStamping || copiedPolygons.length === 0) return;
    
            const targetPoint = [e.lngLat.lng, e.lngLat.lat];
            const offset = calculatePolygonOffset(copiedPolygons[0], targetPoint);
    
            const previewPolygons = copiedPolygons.map((feature) => ({
                ...feature,
                geometry: {
                    ...feature.geometry,
                    coordinates: feature.geometry.coordinates.map((ring) =>
                        ring.map(([lng, lat]) => [lng + offset.lng, lat + offset.lat])
                    )
                }
            }));
    
            if (map.current.getSource(previewSourceId)) {
                map.current.getSource(previewSourceId).setData({
                    type: 'FeatureCollection',
                    features: previewPolygons,
                });
            } else {
                map.current.addSource(previewSourceId, {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: previewPolygons,
                    },
                });
    
                map.current.addLayer({
                    id: previewLayerId,
                    type: 'fill',
                    source: previewSourceId,
                    paint: {
                        'fill-color': '#ffcc00',
                        'fill-opacity': 0.5,
                        'fill-outline-color': '#ff9900',
                    },
                });
            }
        };
        const handleStampClick = (e) => {
            if (!isStamping || copiedPolygons.length === 0) return;
    
            const targetPoint = [e.lngLat.lng, e.lngLat.lat];
            const offset = calculatePolygonOffset(copiedPolygons[0], targetPoint);
    
            const stampedPolygons = copiedPolygons.map((feature) => ({
                ...feature,
                geometry: {
                    ...feature.geometry,
                    coordinates: feature.geometry.coordinates.map((ring) =>
                        ring.map(([lng, lat]) => [lng + offset.lng, lat + offset.lat])
                    )
                }
            }));
    
            setGeoJson(prev => ({
                ...prev,
                features: [...prev.features, ...stampedPolygons]
            }));
    
            map.current.getSource('geojson').setData({
                ...geoJson,
                features: [...geoJson.features, ...stampedPolygons]
            });

            handleExitStampMode({ key: 'Escape' });
        };
        const handleExitStampMode = (e) => {
            if (e.key === 'Escape') {
                isStamping = false;
                map.current.off('mousemove', handleStampPreview);
                map.current.off('click', handleStampClick);
                window.removeEventListener('keydown', handleExitStampMode);
                map.current.getCanvas().style.cursor = '';
    
                if (map.current.getSource(previewSourceId)) {
                    map.current.removeLayer(previewLayerId);
                    map.current.removeSource(previewSourceId);
                }
            }
        };
    
        map.current.once('click', handleFirstClick);
    };
    
        /**
     * Calculate the offset between the first polygon's reference point and the target point.
     * @param {Object} polygon - The first polygon from the copied selection.
     * @param {Array} targetPoint - The [lng, lat] of the cursor location.
     * @returns {Object} - Offset values { lng, lat }.
     */
    const calculatePolygonOffset = (polygon, targetPoint) => {
        if (!polygon || !polygon.geometry || !polygon.geometry.coordinates) {
            console.warn('Invalid polygon data for offset calculation.');
            return { lng: 0, lat: 0 };
        }
        const referencePoint = polygon.geometry.coordinates[0][0];
        const offset = {
            lng: targetPoint[0] - referencePoint[0],
            lat: targetPoint[1] - referencePoint[1],
        };

        return offset;
    };

    //NEW STUFF HERERERE _+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A
    //_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A
    //_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A_+_+_+_+_+_+A

    const handleLabelingTool = () => {
        console.log('Labeling tool activated. Select two points to define the labeling area.');
    
        map.current.getCanvas().style.cursor = 'crosshair';
        let rectangleStart = null;
        const handleFirstClick = (e) => {
            rectangleStart = [e.lngLat.lng, e.lngLat.lat];
            map.current.on('mousemove', handleMouseMove);
            map.current.once('click', handleSecondClick);
        };
        const handleMouseMove = (e) => {
            if (!rectangleStart) return;
            drawRectanglePreview(rectangleStart, [e.lngLat.lng, e.lngLat.lat]);
        };
        const handleSecondClick = (e) => {
            if (!rectangleStart) return;
    
            const rectangleEnd = [e.lngLat.lng, e.lngLat.lat];
            const bounds = {
                west: Math.min(rectangleStart[0], rectangleEnd[0]),
                east: Math.max(rectangleStart[0], rectangleEnd[0]),
                south: Math.min(rectangleStart[1], rectangleEnd[1]),
                north: Math.max(rectangleStart[1], rectangleEnd[1]),
            };
            const selectedPolygons = geoJson.features.filter((feature) => {
                if (feature.geometry.type !== 'Polygon') return false;
    
                return feature.geometry.coordinates[0].every(([lng, lat]) => {
                    return (
                        lng >= bounds.west &&
                        lng <= bounds.east &&
                        lat >= bounds.south &&
                        lat <= bounds.north
                    );
                });
            });
    
            if (selectedPolygons.length === 0) {
                alert('No polygons selected in the drawn area.');
                cleanupTool();
                return;
            }
            const inverterBlock = prompt('Enter Inverter Block (e.g., 12):', '');
            const group = prompt('Enter Group (e.g., 3):', '');
    
            if (!inverterBlock || isNaN(inverterBlock) || !group || isNaN(group)) {
                alert('Invalid input. Inverter Block and Group must be numbers.');
                cleanupTool();
                return;
            }
            const sortedPolygons = defineRowsAndColumns(selectedPolygons);
            let updatedFeatures = geoJson.features.map((feature) => {
                const matchingPolygon = sortedPolygons.find(
                    (sp) => sp.geometry.coordinates[0][0][0] === feature.geometry.coordinates[0][0][0] &&
                            sp.geometry.coordinates[0][0][1] === feature.geometry.coordinates[0][0][1]
                );
    
                if (matchingPolygon) {
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            location: `IB${inverterBlock}-G${group}-R${matchingPolygon.row}N-C${matchingPolygon.column}W`,
                        },
                    };
                }
                return feature;
            });
            setGeoJson({ ...geoJson, features: updatedFeatures });
            map.current.getSource('geojson').setData({
                type: 'FeatureCollection',
                features: updatedFeatures,
            });
    
            alert(`Labeled ${selectedPolygons.length} polygons with IB${inverterBlock}-G${group}.`);
            cleanupTool();
        };
        const cleanupTool = () => {
            rectangleStart = null;
            map.current.off('mousemove', handleMouseMove);
            map.current.getCanvas().style.cursor = '';
            if (map.current.getSource('preview')) {
                map.current.removeLayer('preview-layer');
                map.current.removeSource('preview');
            }
            console.log('Labeling tool deactivated.');
        };
        const drawRectanglePreview = (start, end) => {
            const previewRectangle = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [[
                        [start[0], start[1]],
                        [end[0], start[1]],
                        [end[0], end[1]],
                        [start[0], end[1]],
                        [start[0], start[1]]
                    ]]
                },
                properties: { isPreview: true }
            };
    
            if (map.current.getSource('preview')) {
                map.current.getSource('preview').setData(previewRectangle);
            } else {
                map.current.addSource('preview', {
                    type: 'geojson',
                    data: previewRectangle
                });
    
                map.current.addLayer({
                    id: 'preview-layer',
                    type: 'fill',
                    source: 'preview',
                    paint: {
                        'fill-color': '#00ff00',
                        'fill-opacity': 0.3,
                        'fill-outline-color': '#00ff00'
                    }
                });
            }
        };
        map.current.once('click', handleFirstClick);
    };
    
    /**
     * Define rows and columns for polygons based on centroid latitude and longitude.
     * @param {Array} polygons - Array of polygon features from GeoJSON.
     * @returns {Array} - Polygons annotated with row and column properties.
     */
    const defineRowsAndColumns = (polygons) => {
        if (!polygons || polygons.length === 0) return [];

        // Step 1: Calculate centroids
        const calculateCentroid = (coordinates) => {
            let lngSum = 0, latSum = 0, count = 0;
            coordinates[0].forEach(([lng, lat]) => {
                lngSum += lng;
                latSum += lat;
                count++;
            });
            return [lngSum / count, latSum / count];
        };

        const polygonsWithCentroids = polygons.map((polygon) => ({
            ...polygon,
            centroid: calculateCentroid(polygon.geometry.coordinates),
        }));

        // Step 2: Extract and sort unique latitudes and longitudes
        const uniqueLatitudes = [
            ...new Set(polygonsWithCentroids.map((p) => p.centroid[1]))
        ].sort((a, b) => b - a); // North to South (Descending)

        const uniqueLongitudes = [
            ...new Set(polygonsWithCentroids.map((p) => p.centroid[0]))
        ].sort((a, b) => a - b); // West to East (Ascending)

        // Step 3: Assign Row and Column Numbers
        const annotatedPolygons = polygonsWithCentroids.map((polygon) => {
            const row = uniqueLatitudes.indexOf(polygon.centroid[1]) + 1;
            const column = uniqueLongitudes.indexOf(polygon.centroid[0]) + 1;

            return {
                ...polygon,
                row,
                column,
            };
        });

        console.log(`Assigned ${annotatedPolygons.length} polygons to rows and columns.`);
        return annotatedPolygons;
    };

    // HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE HEY LOOK OVER HERE 
    // &%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%
    // &%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%
    // &%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%
    // &%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%&%

    const handleDrawInverterBlock = () => {
        console.log('Inverter Block Drawing Tool Activated');
    
        // State variables
        let isDrawing = false;
        let currentPolygon = [];
        let currentVertexIndex = null;
    
        // UI Window State
        const drawingWindow = document.createElement('div');
        drawingWindow.id = 'drawing-window';
        drawingWindow.innerHTML = `
            <div style="position: fixed; top: 50px; right: 90px; background: white; border: 1px solid #ccc; padding: 10px; z-index: 1000;">
                <button id="save-polygon">Save</button>
                <button id="cancel-polygon">Cancel</button>
            </div>
        `;
        document.body.appendChild(drawingWindow);
    
        map.current.getCanvas().style.cursor = 'crosshair';
    
        const startDrawing = (e) => {
            const clickedPoint = [e.lngLat.lng, e.lngLat.lat];
    
            if (!isDrawing) {
                isDrawing = true;
                currentPolygon.push(clickedPoint);
                map.current.on('mousemove', previewPolygon);
            } else {
                // Check if the user clicked the first vertex to close the polygon
                if (isClickingStartVertex(clickedPoint)) {
                    completePolygon();
                    return;
                }
    
                currentPolygon.push(clickedPoint);
                updatePolygonPreview();
                updateVertexDots();
            }
        };
    
        // ✅ Show Polygon Preview
        const previewPolygon = (e) => {
            if (!isDrawing) return;
    
            const tempPolygon = [...currentPolygon, [e.lngLat.lng, e.lngLat.lat]];
            drawPolygonPreview(tempPolygon);
        };
    
        const completePolygon = () => {
            if (currentPolygon.length < 3) {
                alert('A polygon requires at least 3 points.');
                cleanupDrawing();
                return;
            }
    
            currentPolygon.push(currentPolygon[0]);
            isDrawing = false;
            map.current.off('mousemove', previewPolygon);
            map.current.off('click', startDrawing);
            drawPolygonPreview(currentPolygon, true);
            enableVertexDragging();
        };

        const enableVertexDragging = () => {
            map.current.dragPan.disable(); // Disable map dragging
        
            map.current.on('mousedown', (e) => {
                const clickedPoint = [e.lngLat.lng, e.lngLat.lat];
                currentVertexIndex = findClosestVertex(clickedPoint);
        
                if (currentVertexIndex !== null) {
                    highlightVertex(currentVertexIndex);
                }
            });
        
            map.current.on('mousemove', (e) => {
                if (currentVertexIndex === null) return;
        
                currentPolygon[currentVertexIndex] = [e.lngLat.lng, e.lngLat.lat];
                drawPolygonPreview(currentPolygon, true);
                updateVertexDots();
            });
        
            map.current.on('mouseup', () => {
                currentVertexIndex = null;
                removeVertexHighlight();
            });
        };
    
        const savePolygon = () => {
            const newPolygonFeature = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [currentPolygon],
                },
                properties: {
                    type: 'inverter-block-outline',
                },
            };
    
            setGeoJson((prev) => ({
                ...prev,
                features: [...prev.features, newPolygonFeature],
            }));
    
            map.current.getSource('geojson').setData({
                type: 'FeatureCollection',
                features: [...geoJson.features, newPolygonFeature],
            });
            cleanupDrawing();
        };
    
        const cancelPolygon = () => {
            cleanupDrawing();
        };
    
        const cleanupDrawing = () => {
            isDrawing = false;
            currentPolygon = [];
            currentVertexIndex = null;
    
            map.current.off('click', startDrawing);
            map.current.off('mousemove', previewPolygon);
            map.current.off('mousedown');
            map.current.off('mousemove');
            map.current.off('mouseup');

            map.current.dragPan.enable();
    
            map.current.getCanvas().style.cursor = '';
            ['preview-polygon', 'vertex-dots'].forEach((sourceId) => {
                if (map.current.getSource(sourceId)) {
                    map.current.removeLayer(`${sourceId}-layer`);
                    map.current.removeSource(sourceId);
                }
            });
    
            document.body.removeChild(drawingWindow);
        };
    
        const drawPolygonPreview = (polygon, isFinal = false) => {
            const previewPolygon = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [polygon],
                },
                properties: { isPreview: true },
            };
    
            if (map.current.getSource('preview-polygon')) {
                map.current.getSource('preview-polygon').setData(previewPolygon);
            } else {
                map.current.addSource('preview-polygon', {
                    type: 'geojson',
                    data: previewPolygon,
                });
    
                map.current.addLayer({
                    id: 'preview-polygon-layer',
                    type: 'fill',
                    source: 'preview-polygon',
                    paint: {
                        'fill-color': isFinal ? '#FF8800' : '#00FF00',
                        'fill-opacity': 0.5,
                        'fill-outline-color': '#FF5500',
                    },
                });
            }
        };
    
        const updateVertexDots = () => {
            const vertexPoints = currentPolygon.map(([lng, lat]) => ({
                type: 'Feature',
                geometry: { type: 'Point', coordinates: [lng, lat] },
            }));
    
            if (map.current.getSource('vertex-dots')) {
                map.current.getSource('vertex-dots').setData({
                    type: 'FeatureCollection',
                    features: vertexPoints,
                });
            } else {
                map.current.addSource('vertex-dots', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: vertexPoints,
                    },
                });
    
                map.current.addLayer({
                    id: 'vertex-dots-layer',
                    type: 'circle',
                    source: 'vertex-dots',
                    paint: {
                        'circle-radius': 5,
                        'circle-color': '#FF0000',
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#FFFFFF',
                    },
                });
            }
        };

        const isClickingStartVertex = ([lng, lat]) => {
            const [startLng, startLat] = currentPolygon[0];
            return Math.hypot(lng - startLng, lat - startLat) < 0.0001;
        };


        /**
         * Highlights a specific vertex on the map.
         * @param {number} index - Index of the vertex to highlight.
         */
        const highlightVertex = (index) => {
            const vertex = currentPolygon[index];
            if (!vertex) return;

            const highlightedVertex = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: vertex,
                },
            };

            if (map.current.getSource('highlighted-vertex')) {
                map.current.getSource('highlighted-vertex').setData(highlightedVertex);
            } else {
                map.current.addSource('highlighted-vertex', {
                    type: 'geojson',
                    data: highlightedVertex,
                });

                map.current.addLayer({
                    id: 'highlighted-vertex-layer',
                    type: 'circle',
                    source: 'highlighted-vertex',
                    paint: {
                        'circle-radius': 15,
                        'circle-color': '#FFD700', 
                        'circle-stroke-width': 3,
                        'circle-stroke-color': '#FFFFFF',
                    },
                });
            }
        };

            /**
         * Finds the closest vertex within a defined radius.
         * @param {Array} point - [lng, lat] of the clicked point.
         * @returns {number|null} - Index of the closest vertex, or null if none found.
         */
        const findClosestVertex = (point) => {
            let closestIndex = null;
            let minDistance = Infinity;
            const clickRadius = getClickRadius();

            currentPolygon.forEach(([lng, lat], index) => {
                const distance = calculateDistance(point, [lng, lat]);
                if (distance < clickRadius && distance < minDistance) {
                    minDistance = distance;
                    closestIndex = index;
                }
            });

            if (closestIndex !== null) {
                highlightVertex(closestIndex); 
            }

            return closestIndex;
        };

        const updatePolygonPreview = () => {
            if (currentPolygon.length < 2) return;

            const previewPolygon = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [currentPolygon],
                },
                properties: { isPreview: true },
            };

            if (map.current.getSource('preview-polygon')) {
                map.current.getSource('preview-polygon').setData(previewPolygon);
            } else {
                map.current.addSource('preview-polygon', {
                    type: 'geojson',
                    data: previewPolygon,
                });

                map.current.addLayer({
                    id: 'preview-polygon-layer',
                    type: 'fill',
                    source: 'preview-polygon',
                    paint: {
                        'fill-color': '#00FF00',
                        'fill-opacity': 0.5,
                        'fill-outline-color': '#FF5500',
                    },
                });
            }
        };
    
        // Attach Event Listeners
        map.current.on('click', startDrawing);
        map.current.once('dblclick', completePolygon);
    
        document.getElementById('save-polygon').onclick = savePolygon;
        document.getElementById('cancel-polygon').onclick = cancelPolygon;
    };

    /**
     * Calculates the distance between two geographic points in meters.
     * @param {Array} point1 - [lng, lat] of the first point.
     * @param {Array} point2 - [lng, lat] of the second point.
     * @returns {number} - Distance in meters.
     */
    const calculateDistance = ([lng1, lat1], [lng2, lat2]) => {
        const R = 6371000; // Earth radius in meters
        const rad = Math.PI / 180;
        const dLat = (lat2 - lat1) * rad;
        const dLng = (lng2 - lng1) * rad;
        const a =
            Math.sin(dLat / 2) ** 2 +
            Math.cos(lat1 * rad) * Math.cos(lat2 * rad) * Math.sin(dLng / 2) ** 2;
        return R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    };

    /**
     * Gets a dynamic click radius based on the current zoom level.
     * @returns {number} - Click radius in meters.
     */
    const getClickRadius = () => {
        const zoom = map.current.getZoom();
        if (zoom > 15) return 15; // 5 meters for detailed zoom levels
        if (zoom > 13) return 25; // 10 meters for mid-level zoom
        return 20; // 20 meters for lower zoom levels
    };

    /**
     * Removes the vertex highlight from the map.
     */
    const removeVertexHighlight = () => {
        if (map.current.getSource('highlighted-vertex')) {
            map.current.removeLayer('highlighted-vertex-layer');
            map.current.removeSource('highlighted-vertex');
        }
    };

    /* 
        37.055203, -122.013148
    */

    return (
        <div className="page-wrapper">
            {/* Main Map Page */}
            <div className="map-page">
                
                {/* Map Container */}
                <div ref={mapContainer} className="map-container">
                    <div className={`toolbar ${isSidePanelOpen ? 'shifted' : ''}`}>

                        <button className="map-button" onClick={handleNeutralCursor} aria-label="Neutral Cursor" title="Neutral Cursor">
                            <PanToolIcon />
                        </button>
                        <button className="map-button" onClick={handleZoomIn} aria-label="Zoom In" title="Zoom In">
                            <ZoomInIcon />
                        </button>
                        <button className="map-button" onClick={handleZoomOut} aria-label="Zoom Out" title="Zoom Out">
                            <ZoomOutIcon />
                        </button>
                        <button className="map-button" onClick={handleResetMap} aria-label="Center Map" title="Center Map">
                            <GpsFixedIcon />
                        </button>
                        <button className="map-button" onClick={handleDrawSolarGrid} aria-label="Draw Solar Grid" title="Draw Solar Grid">
                            <CropSquareIcon />
                        </button>
                        <button className="map-button" onClick={handleCopyStampTool} aria-label="Copy & Stamp Tool" title="Copy & Stamp Tool">
                            <FileCopyIcon  />
                        </button>
                        <button className="map-button" onClick={handleDeleteArea} aria-label="Delete Selected Area" title="Delete Selected Area">
                            <DeleteIcon />
                        </button>
                        <button className="map-button" onClick={handleLabelingTool} aria-label="Label Polygons" title="Label Polygons in Selected Area">
                            <LabelIcon />
                        </button>
                        <button className="map-button" onClick={handleDrawInverterBlock} aria-label="Draw Inverter Block Outline" title="Draw Inverter Block Outline">
                            <HighlightAltOutlinedIcon />
                        </button>

                    </div>
                </div>
    
                <div
                    className={`hamburger-icon ${isSidePanelOpen ? "shifted" : ""}`}
                    onClick={handleToggleSidePanel}
                    title="View GeoJSON"
                >
                    &#9776;
                </div>

                <div className={`side-panel ${isSidePanelOpen ? "open" : ""}`}>
                    <h3>GeoJSON Content</h3>
                    {isEditing ? (
                        <>
                            <button onClick={handleSaveEditedGeoJson}>Save</button>
                            <button onClick={() => setIsEditing(false)}>Cancel</button>
                            <textarea
                                id="geojson-textarea"
                                defaultValue={JSON.stringify(geoJson, null, 2)}
                                className="geojson-textarea"
                            ></textarea>

                        </>
                    ) : (
                        <>
                            <div className="button-container">
                                <button onClick={() => setIsEditing(true)}>
                                    <span className="button-icon">✏️</span> Edit GeoJSON
                                </button>
                                <button onClick={handleExportGeoJson}>
                                    <span className="button-icon">⬇️</span> Export GeoJSON
                                </button>
                            </div>
                            <pre>{JSON.stringify(geoJson, null, 2)}</pre>
                        </>
                    )}
                </div>
            </div>
        </div>
    );    
    
}

export default MapPage;
